@use '@angular/material' as mat;
@use './themes' as *;

@include mat.all-component-themes($light-theme);

html, body {
  //  min-height: 100%;
}

body {
  margin: 0;
}

html, body, button, input {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

a.mdc-button .mdc-button__label {
  font-size: 12pt;
}

#error-container {
  position: fixed;
  top: 20px;
  right: 20px;
  bottom: 20px;
  left: 20px;
  background: #f88;
  padding: 15px;
  z-index: 9999999;
  display: flex;
  flex-direction: column;
  border: 3px solid #f00;
  border-radius: 10px;

  pre {
    flex: 1;
    overflow-y: scroll;
  }
}

#toast-container > div {
  width: min(100vw - 20px, 800px);
}

.toast-message > .pre {
  white-space: pre-wrap;
  font-family: monospace;
}

form {
  display: flex;
  flex-direction: column;
  gap: 1.5em;
  margin: 1.5em 0;

  & > :first-child { // fixes first field-label is cut
    margin-top: 4px;
  }
}

.loader-spinner {
  background-color: rgba(0.15, 0.15, 0.15, 0.05);
  z-index: 1000000;
  align-content: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  .mat-mdc-progress-spinner {
    justify-self: center;
    margin: auto;
    background-color: rgba(0.15, 0.15, 0.15, 0.1);
  }

  &.hide {
    opacity: 0;
    visibility: hidden;
    transition: visibility 0s 0.6s, opacity 0.3s ease-out;
  }
}


div.form-row-30-70 {
  display: grid;
  grid-gap: 20px;
  grid-template-columns: calc(30% - 20px) 70%;
}

div.form-row-50-50 {
  display: grid;
  grid-gap: 20px;
  grid-template-columns: calc(50% - 20px) 50%;
}

div.form-row-70-30 {
  display: grid;
  grid-gap: 20px;
  grid-template-columns: calc(70% - 20px) 30%;
}

div.form-row-35-35-30 {
  display: grid;
  grid-gap: 20px;
  grid-template-columns: 35% 35% calc(30% - 40px);
}

div.form-row-48-26-26 {
  display: grid;
  grid-gap: 20px;
  grid-template-columns: calc(48% - 40px) 26% 26%;
}

.mdc-button.mat-mdc-button.disabled {
  pointer-events: none;
  color: #b0b0b0;
}

.underline {
  text-decoration: underline;
}

button.inline-button {
  display: inline-block;
  margin: 0 5px;
  padding: 0 10px;
  height: 1.7em;
}

.note {
  background-color: #eee;
  color: #555;
  border: 3px dashed #ccc;
  margin: 1em 0 2em;
  padding: 0.5em 1em
}

mat-table .mdc-button {
  padding: 0 1em;
  height: 2em;
}
