@use '@angular/material' as mat;

@include mat.elevation-classes();
@include mat.app-background();

/* For use in src/lib/core/theming/_palette.scss */
$md-basepalette: (
  50 : #e5e9ea,
  100 : #bec8cb,
  200 : #93a3a9,
  300 : #677e87,
  400 : #47626d,
  500 : #264653,
  600 : #223f4c,
  700 : #1c3742,
  800 : #172f39,
  900 : #0d2029,
  A100 : #68c8ff,
  A200 : #35b5ff,
  A400 : #02a3ff,
  A700 : #0093e7,
  contrast: (
    50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #ffffff,
    400 : #ffffff,
    500 : #ffffff,
    600 : #ffffff,
    700 : #ffffff,
    800 : #ffffff,
    900 : #ffffff,
    A100 : #000000,
    A200 : #000000,
    A400 : #ffffff,
    A700 : #ffffff,
  )
);

$md-warnpalette: (
  50 : #fceeea,
  100 : #f8d4cb,
  200 : #f3b7a8,
  300 : #ee9a85,
  400 : #eb856b,
  500 : #e76f51,
  600 : #e4674a,
  700 : #e05c40,
  800 : #dd5237,
  900 : #d74027,
  A100 : #ffffff,
  A200 : #ffe4e0,
  A400 : #ffb8ad,
  A700 : #ffa194,
  contrast: (
    50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #000000,
    400 : #000000,
    500 : #000000,
    600 : #000000,
    700 : #000000,
    800 : #ffffff,
    900 : #ffffff,
    A100 : #000000,
    A200 : #000000,
    A400 : #000000,
    A700 : #000000,
  )
);

$md-accentgpalette: (
  50 : #fcf8ed,
  100 : #f8edd2,
  200 : #f4e2b5,
  300 : #f0d697,
  400 : #eccd80,
  500 : #e9c46a,
  600 : #e6be62,
  700 : #e3b657,
  800 : #dfaf4d,
  900 : #d9a23c,
  A100 : #ffffff,
  A200 : #fffbf3,
  A400 : #ffe8c0,
  A700 : #ffdea7,
  contrast: (
    50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #000000,
    400 : #000000,
    500 : #000000,
    600 : #000000,
    700 : #000000,
    800 : #000000,
    900 : #000000,
    A100 : #000000,
    A200 : #000000,
    A400 : #000000,
    A700 : #000000,
  )
);


$light-theme: mat.m2-define-light-theme((
  color: (
    primary: mat.m2-define-palette($md-basepalette, 400),
    accent: mat.m2-define-palette($md-accentgpalette, 700, 800, 900),
    warn: mat.m2-define-palette($md-warnpalette, 700)
  )
));
